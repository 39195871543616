<template>
<div class="home">
        <div class="features">
            <h4 style="color:#ffff;">{{$t('Validación')}}</h4>
            <h5 style="color:#ffff;">{{mensaje}}</h5>

            <div class="p-grid" >
                <div class="p-col-12 p-md-12">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">{{$t('Póliza')}}</div>
							<div class="p-fluid p-formgrid p-grid" style="text-align: left;">
								<div class="p-field p-col-12 p-md-6">
									<strong>{{$t('Insurance Cover Note #')}} </strong>{{codigo}}
								</div>
								<div class="p-field p-col-12 p-md-6">
									<strong>{{$t('Nombre Completo')}}: </strong>{{nombre}}
								</div>
								<div class="p-field p-col-12 p-md-6">
									<strong>{{$t('Fecha de Expiración')}}: </strong>{{fecha}}
								</div>
								<div class="p-field p-col-12 p-md-6">
									<strong>
                                        <div v-if="estado==0">
                                            {{$t('Estatus')}}: {{$t('Anulada')}}
                                        </div>
                                        <div v-if="estado==6">
                                            {{$t('Estatus')}}: {{$t('Cancelada')}}
                                        </div>
                                        <div v-else>
                                            <div v-if="vend<=0">
                                                {{$t('Estatus')}}: {{$t('Vencida')}}
                                            </div>
                                            <div v-else-if="estado==6">
                                                {{$t('Estatus')}}: {{$t('Cancelada')}}
                                            </div>
                                            <div v-else-if="pendiente>0">
                                                {{$t('Estatus')}}: {{$t('Deuda Vencida')}} <br> ({{$t('Suspendida')}})
                                            </div>
                                            <div v-else-if="proceso>0">
                                                {{$t('Estatus')}}: {{$t('Pago Vencido')}} <br> ({{$t('Suspendida')}})
                                            </div>
                                            <div v-else>
                                                {{$t('Estatus')}}: {{$t('Activa')}}
                                            </div>
                                        </div>
                                    </strong>
								</div>
                                <div class="p-field p-col-12 p-md-12">
                                    {{nota}}
                                </div>
                                <div class="p-field p-col-6 p-md-3">
                                    <Button label="DAI SH General" icon="pi pi-file-pdf" class="p-mr-2 p-mb-2" @click="ver('terms/DAI_SH_General_Policy_Conditions.pdf')"></Button>
                                </div>
                                <div class="p-field p-col-6 p-md-3">
                                    <Button label="DAI SH Motor Policy Conditions" icon="pi pi-file-pdf" class="p-mr-2 p-mb-2" @click="ver('terms/DAI_SH_Motor_Policy_Conditions.pdf')"></Button>
                                </div>
							</div>
                        </div>
                    </div>
                </div>
			</div>
        </div>
</div>


</template>

<script>
import API from "@/service/API";
const Consulta = new API('Query','Validacion');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";

//import url from "@/service/_URL";

export default {
    data () {
        return {
			cod: null,
            mensaje: null,
            codigo: null,
            fecha: null,
            nombre: null,
            estado_nombre: null,
            estado_name: null,
            estado: null,
            pendiente: null,
            proceso: null,
            vend: null,
            nota: null,
            url: null,
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.cod = this.$route.params.cod;	
        this.url = url;
		this.Mostrar(this.cod);		
		
	},
    methods: {
        Mostrar(cod){
			this.poliza = [];
			Consulta.Procesar('Validacion',{
				cod: cod,
				}).then(response => {
                    if (response.result){
                        this.codigo = response.result.poliza.codigo;
                        this.nombre = response.result.poliza.nombre;
                        this.fecha = this.convfech(response.result.poliza.vence);
                        this.estado_nombre = response.result.poliza.estado_nombre;
                        this.estado_name = response.result.poliza.estado_name;
                        this.estado = response.result.poliza.estado;
                        this.pendiente = response.result.poliza.pendiente;
                        this.proceso = response.result.poliza.proceso;
                        this.vend = response.result.poliza.vend;
                    } else {
                        this.mensaje = 'NO';
                    }
                });
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        ver(url){
            window.open(url,'_blank');
		},
    }
}
</script>